/* eslint-disable react/prop-types */
import { ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from '@styles/Homepage/checklist/checklist.module.scss';
import { Button, Carousel } from 'antd';

import Link from 'next/link';
import React from 'react';
import { Checklist, GetPublishableChecklists } from 'services';
import ChecklistItem from './ChecklistItem';
import { ComponentBlocksSectionTitle } from 'services/graphql/cms';

const SlickArrowLeft = (props) => {
  const { className, style, onClick } = props;

  return (
    <Button
      type="ghost"
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      <LeftOutlined />
    </Button>
  );
};

const SlickArrowRight = (props) => {
  const { className, style, onClick } = props;

  return (
    <Button
      type="ghost"
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      <RightOutlined />
    </Button>
  );
};

type Props = {
  publicChecklistsData?: NonNullable<GetPublishableChecklists['publishableChecklists']>['data'];
  carouselData: ComponentBlocksSectionTitle;
};

const carouselOptions = {
  dots: false,
  infinite: false,
  draggable: true,
  arrows: true,
  speed: 400,
  slidesToShow: 4,
  swipeToSlide: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: [
    {
      breakpoint: 1599,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ChecklistSection: React.FC<Props> = ({ publicChecklistsData, carouselData }) => (
  <section className={styles.container}>
    <div className={styles.container__content}>
      <div className={styles.content__badge}>New</div>
      <h2 className={styles.content__title}>{carouselData?.title}</h2>
      <div className={styles.content__text} dangerouslySetInnerHTML={{ __html: carouselData?.description }} />
    </div>

    <Carousel {...carouselOptions} className="checklist__carousel">
      {publicChecklistsData.map((item) => (
        <ChecklistItem publicChecklistsData={item as Checklist} key={item.id} />
      ))}
    </Carousel>

    <Link href="/checklists" className={styles.container__button}>
      Browse checklist
      <ArrowRightOutlined />
    </Link>
  </section>
);

export default ChecklistSection;
