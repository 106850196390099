import React, { FC, useState } from 'react';
import styles from '@styles/Homepage/cards.module.scss';
import { ComponentBlocksSectionTitle, GetPricingPlans, PricingPage } from 'services/graphql/cms';
import { PricingCard } from 'sailor-ui/pricing/Card';
import { useKeenSlider } from 'keen-slider/react';
import { Grid } from 'antd';
import { CarouselControllersTop } from 'sailor-ui/CarouselControllersTop';

type Props = {
  plans: NonNullable<GetPricingPlans['plans']>['data'];
  pricingPage: PricingPage;
  cardsData: ComponentBlocksSectionTitle;
};

const Cards: FC<Props> = ({ plans, pricingPage, cardsData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { xs, xxl } = Grid.useBreakpoint();

  const planCardsData = pricingPage?.planCards;

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'snap',
    slides: {
      spacing: 0,
      perView: 'auto',
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  return (
    <section className={styles.container}>
      <div className={styles.containerContent}>
        <h2 className={styles.contentTitle}>{cardsData?.title}</h2>
        <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: cardsData?.description }} />
      </div>

      <div className={styles.carouselControllerContainer}>
        {!xxl && (
          <CarouselControllersTop
            shape="default"
            currentSlide={currentSlide}
            instanceRef={instanceRef}
            nrOfDots={xs ? plans?.length || 0 : 0}
          />
        )}
      </div>

      <div ref={ref} className={`keen-slider, ${styles.containerCards}`}>
        {planCardsData?.length
          ? planCardsData.map(
              (plan) =>
                plan?.planName && (
                  <div key={plan.id} className="keen-slider__slide">
                    <PricingCard key={plan.id} plan={plan} showFeatures={false} />
                  </div>
                ),
            )
          : null}
      </div>
    </section>
  );
};

export default Cards;
